import Sections from '@organisms/Sections/Sections';
import PropTypes from 'prop-types';

function ContentDetail({ page }) {
  return (
    <>
      <h1 className="u-visually-hidden">{page.title}</h1>
      <Sections sections={page.sections} />
    </>
  );
}

ContentDetail.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, typename: PropTypes.string })
    ),
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentDetail;
