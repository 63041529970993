import getOrCreateCustomerId from '@lib/customer';
import getConfigProps from '@lib/getConfigProps';
import { getContentPage } from '@lib/prepr';
import { seoTypes } from '@lib/proptypes';
import ContentDetail from '@organisms/ContentDetail/ContentDetail';
import PropTypes from 'prop-types';

function ContentPage({ page }) {
  return <ContentDetail page={page} />;
}

ContentPage.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, typename: PropTypes.string })
    ),
    title: PropTypes.string.isRequired,
    ...seoTypes,
  }).isRequired,
};

export async function getServerSideProps(ctx) {
  const { req, params, locale, preview = false } = ctx;

  const customerId = getOrCreateCustomerId(req);
  const { host } = req.headers;
  const { slug } = params;

  const contentPage = getContentPage({
    host,
    slug: `/${slug.join('/')}`,
    locale,
    customerId,
    preview,
  });

  const [
    page,
    {
      props: { config },
    },
  ] = await Promise.all([contentPage, getConfigProps(ctx)]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      preview,
      page,
      config: {
        ...config,
        ...(page.hideNavigationMenu ? { mainMenu: [] } : {}),
      },
      host,
      customerId,
    },
  };
}

export default ContentPage;
